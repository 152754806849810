import { Box, Container, HStack, Image, chakra } from "@chakra-ui/react";
import { SkipNavContent, SkipNavLink } from "@chakra-ui/skip-nav";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Link from "../../../shared/components/link";

import useMenuItems from "./linkItems";
import Logo from "./Logo.svg";
import MobileNav from "./MobileNav";
import NavbarLogin from "./NavbarLogin";
import NavbarSearch from "./NavbarSearch";
import NavItem from "./NavItem";

export enum NavbarMenu {
  Mobile,
  PillarPages,
  User,
}

export interface NavbarMenuToggleProps {
  isOpen?: boolean;
  onToggle: () => void;
}

const Navbar = () => {
  const location = useLocation();
  const [pillarMenuItem, menuItems] = useMenuItems();
  const [activeMenu, setActiveMenu] = useState<NavbarMenu | undefined>(
    undefined
  );

  useEffect(() => {
    setActiveMenu(undefined);
  }, [location]);

  const getMenuToggleProps = (menu: NavbarMenu) => {
    const isOpen = activeMenu === menu;
    const onToggle = () => {
      if (isOpen) {
        setActiveMenu(undefined);
      } else {
        setActiveMenu(menu);
      }
    };

    return {
      isOpen,
      onToggle,
    };
  };

  return (
    <>
      <SkipNavLink>{t("main:navbar.skipNavigation")}</SkipNavLink>
      <chakra.header
        backgroundColor="primary.dark"
        id="header"
        position="fixed"
        top={0}
        width="100%"
        zIndex="popover"
      >
        <Container
          maxWidth="1370px"
          paddingX={{
            base: "16px",
            sm: "20px",
            md: "30px",
            lg: "40px",
            xl: 0,
          }}
        >
          <HStack
            as="nav"
            height="4em"
            justifyContent="space-between"
            spacing="5"
          >
            <Box flexGrow={{ base: 1, lg: "unset" }}>
              <Link display="contents" href="/" title={t("main:navbar.home")!}>
                <Image alt={t("main:navbar.home")!} height={8} src={Logo} />
              </Link>
            </Box>
            <NavItem
              {...pillarMenuItem}
              {...getMenuToggleProps(NavbarMenu.PillarPages)}
            />
            <NavbarSearch />
            {menuItems.map((item) => (
              <NavItem key={item.href} {...item} />
            ))}
            <NavbarLogin />
            <MobileNav {...getMenuToggleProps(NavbarMenu.Mobile)} />
          </HStack>
        </Container>
      </chakra.header>
      <chakra.div height="4em" />
      <SkipNavContent />
    </>
  );
};

export default Navbar;
